@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

html, body, #root{
  height: 100%;
}

/* adding fonts above */
.container{
  @apply px-4 md:px-0 md:w-11/12 lg:w-10/12 max-w-container mx-auto 
}

.link{
  @apply font-medium text-2xl lg:text-base text-white;
}
.link-dark{
  @apply font-medium text-2xl lg:text-base text-white lg:text-accent;
}

.btn{
  @apply py-2 px-4 shadow-md font-medium
}

.btn-white{
  @apply text-primary bg-white
}

.btn-primary{
  @apply text-white bg-primary hover:bg-secondary transition-all focus:bg-white focus:border-2 focus:border-primary focus:text-primary
}


.bg-gradient{
  background: rgb(9,57,83);
  background: -moz-linear-gradient(90deg, rgba(9,57,83,1) 20%, rgba(79,114,132,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(9,57,83,1) 20%, rgba(79,114,132,1) 100%);
  background: linear-gradient(90deg, rgba(9,57,83,1) 20%, rgba(79,114,132,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#093953",endColorstr="#4f7284",GradientType=1);
  @apply text-white
}

.drop-primary {
  -webkit-filter: drop-shadow(0px 2px 10px rgba(9,57,83,0.3));
    filter: drop-shadow(0px 2px 10px rgba(9,57,83,0.3));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
}
.drop-white{
  filter: drop-shadow(0 0 0px rgb(0 0 0 / 0.5)) drop-shadow(0 2px 3px rgb(0 0 0 / 0.5));
}
.my-shadow-primary {
  box-shadow: 0 15px 25px 5px rgba(9,57,83,0.3);
}
.my-shadow-tertiary {
  box-shadow: 0 15px 25px 5px rgba(255,198,66,0.3);
}


.card-input-field {
  @apply w-full py-2 mb-2 rounded-md mt-2 focus:outline-none border-[1px] border-gray-200 px-2
}

.label{
  @apply text-sm block
}





#loginButton:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}




/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .scroller {
    scrollbar-width: auto;
    scrollbar-color: #f57a82 #ffffff;
  }
  
  /* Chrome, Edge, and Safari */
  .scroller::-webkit-scrollbar {
    width: 16px;
  }
  
  .scroller::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  .scroller::-webkit-scrollbar-thumb {
    background-color: #f57a82;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  